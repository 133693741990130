/* eslint-disable no-restricted-imports */
import 'designsystem/src/generated/pillar/festival/tokens.css';
import 'designsystem/src/generated/colorScheme/neutral/tokens.css';
import 'designsystem/src/generated/colorScheme/collection/tokens.css';
import 'designsystem/src/generated/colorScheme/festival/tokens.css';
import 'designsystem/src/generated/colorScheme/professionals/tokens.css';
import 'designsystem/src/generated/colorScheme/institute/tokens.css';
import fallback from 'designsystem/src/assets/images/placeholder.svg';
/* eslint-enable no-restricted-imports */
import 'swiper/css';
import 'swiper/css/autoplay';
import { festivalTheme, GlobalStyle, ThemeProvider, useFontLoaded } from 'designsystem';
import type { AppProps } from 'next/app';
import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FormattedMessage } from 'react-intl';
import { SessionProvider, signOut, useSession } from 'next-auth/react';
import {
    ATProviders,
    GlobalModals,
    IdfaIntlProvider,
    PillarFooter,
    Trengo,
    useLastPageCookie,
    usePurchaseStatus,
} from 'shared';
import NextProgress from 'next-progress';
import { useTheme } from '@emotion/react';
import Script from 'next/script';
import { useArticleDetailQuery, useContentPagesQuery, useGlobalsQuery } from '../gql/cms';
import siteHandleByLocale from '../utils/siteHandleByLocale';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import FestivalNavigation from '../components/FestivalNavigation';
import { QueryParamProvider } from 'use-query-params';
import { NextAdapter } from 'next-query-params';
import { getRedirectSearch } from './[...uri]';

const defaultMetadata: Record<string, { title: string; description: string }> = {
    nl: {
        title: '14 t/m 24 november 2024',
        description:
            'Ontdek het beste van creatieve documentaires op het International Documentary Film Festival Amsterdam. IDFA 2024 vindt plaats van 14 t/m 24 november.',
    },
    en: {
        title: 'November 14-24, 2024',
        description:
            'Explore the best of creative documentaries at the International Documentary Film Festival Amsterdam. IDFA 2023 will take place from November 13th to 14th.',
    },
};

const App = (props: AppProps) => {
    const { pageProps } = props;
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                        retry: 2,
                        refetchOnMount: false,
                        staleTime: 1000 * 10,
                    },
                },
            })
    );
    const { locale, defaultLocale } = useRouter();

    return (
        <QueryParamProvider adapter={NextAdapter}>
            <SessionProvider session={pageProps.session}>
                <Head>
                    <link rel="icon" href="/favicon.ico" sizes="any" />
                </Head>
                {process.env.NODE_ENV !== 'development' && (
                    <>
                        <Script id="google-tag-manager" strategy="afterInteractive">
                            {`
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}');
                        `}
                        </Script>
                        <noscript>
                            <iframe
                                title="gtm-noscript"
                                src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}}`}
                                height="0"
                                width="0"
                                style={{ display: 'none', visibility: 'hidden' }}
                            />
                        </noscript>
                    </>
                )}
                <DefaultSeo
                    openGraph={{
                        type: 'website',
                        locale,
                        url: 'https://www.idfa.nl/',
                        siteName: 'IDFA',
                        images: [
                            {
                                url: fallback,
                            },
                        ],
                    }}
                    additionalMetaTags={[
                        {
                            name: 'author',
                            content: 'Oberon Amsterdam, www.oberon.nl',
                        },
                    ]}
                    twitter={{
                        handle: '@idfa',
                        site: '@idfa',
                        cardType: 'summary_large_image',
                    }}
                    titleTemplate="IDFA Festival | %s"
                    {...defaultMetadata[locale]}
                />
                <IdfaIntlProvider
                    locale={locale as string}
                    defaultLocale={defaultLocale}
                    messages={pageProps.intlMessages}
                >
                    <ThemeProvider theme={festivalTheme}>
                        <QueryClientProvider client={queryClient}>
                            <Hydrate state={pageProps.dehydratedState}>
                                <ATProviders>
                                    <GlobalStyle />
                                    <Main {...props} />
                                    <ReactQueryDevtools initialIsOpen={false} />
                                </ATProviders>
                            </Hydrate>
                        </QueryClientProvider>
                    </ThemeProvider>
                </IdfaIntlProvider>
            </SessionProvider>
        </QueryParamProvider>
    );
};

const Main: FC<AppProps> = ({ pageProps, Component, router }) => {
    const session = useSession();
    const graphikLoaded = useFontLoaded('Graphik');
    const { query, pathname, isFallback } = useRouter();
    const isContentPage = pathname === '/[...uri]';
    const isArticle = pathname === '/news/[slug]';
    const site = siteHandleByLocale[router.locale];
    const { data, isLoading } = useGlobalsQuery(
        {
            site,
        },
        {
            enabled: !isFallback,
        }
    );
    const uri = [...(query?.uri ?? [])].join('/');
    const { data: contentData } = useContentPagesQuery(
        {
            uri,
            site,
            search: getRedirectSearch(uri),
        },
        {
            enabled: isContentPage && !isFallback,
        }
    );
    const { data: articleData } = useArticleDetailQuery(
        {
            site,
            slug: query.slug as string,
        },
        { enabled: isArticle }
    );

    useEffect(() => {
        if (session.data?.error === 'RefreshAccessTokenError') {
            signOut({ redirect: false });
        }
    }, [session.data?.error]);

    const { tokens } = useTheme();
    useLastPageCookie();

    usePurchaseStatus();

    // bit of a hack where we check if the component is embedded in another page
    const embedded = (Component as typeof Component & { embed: boolean }).embed;

    return (
        <main
            id="root"
            style={{ minWidth: 'fit-content' }}
            data-font-loaded={graphikLoaded !== null ? graphikLoaded : undefined}
        >
            <NextProgress delay={300} options={{ showSpinner: false }} color={tokens.SyntaxPrimaryColorDarker} />
            {!embedded && (
                <FestivalNavigation isLoading={isLoading} globals={data} content={contentData ?? articleData} />
            )}
            <Component {...pageProps} />
            {!embedded && (
                <>
                    <Trengo />
                    <PillarFooter
                        globals={data}
                        isLoading={isLoading}
                        pillarTitle={<FormattedMessage defaultMessage="IDFA Festival" />}
                    />
                </>
            )}
            <GlobalModals />
        </main>
    );
};

export default App;
